<template>
    <v-row>
        <v-col cols="12" lg="8" md="12">
            <div class="mb-2">
                <transition name="grow">
                    <div :class="{'grow-expand': selectedUser != null, 'grow': selectedUser == null}">
                    <v-footer tile flat class="font-weight-medium shadow-md"
                        :class="$vuetify.breakpoint.smAndUp ? 'pr-2 details_height' : 'details_height_mobile'"
                        :color="$vuetify.theme.dark ? 'dark' : 'white'" :dark="$vuetify.theme.dark" :inset="true">
                        <v-col class="text-left py-0" cols="12">
                            <v-row class="py-0" v-if="this.$vuetify.breakpoint.smAndUp">
                                <v-col cols="4" :class="this.$vuetify.breakpoint.xs ? 'mt-2' : ''"
                                    class="py-0 d-flex align-center"
                                    :style="this.$vuetify.breakpoint.xs ? 'justify-content: flex-end' : ''">
                                    <div style="position: relative;">
                                        <div>
                                            <h5>
                                                Search player history
                                            </h5>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="5">
                                    <v-autocomplete v-model="selectedUser" :loading="loading" :items="getSearchedUsers"
                                        :search-input.sync="search" item-text="text" return-object cache-items
                                        hide-no-data outlined clearable hide-details dense ref="searchField"
                                        key="searchField" autofocus filled placeholder="Type displayname">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="3">
                                    <v-select id="matchdaySelect" :items="previousGameweeks" label="Matchday" outlined
                                        dense filled hide-details @change="updateGameweek"
                                        v-model="selectedPredictions" />
                                </v-col>
                            </v-row>
                            <v-row class="py-0" v-if="this.$vuetify.breakpoint.smAndUp">
                                <v-col cols="12" class="py-0 px-0 d-flex align-center"
                                    style="justify-content: space-evenly;">
                                    <div>
                                        <h6>
                                            {{ this.selectedUser ? this.selectedUser.text : 'No player selected' }}
                                        </h6>
                                    </div>
                                    <div style="display: flex;">
                                        <span>
                                            <h6>{{ !this.$vuetify.breakpoint.xs ? 'Position' : 'Pos:' }}
                                                <span>{{ getGameweekResult.position == 0 ? 'N/A' :
                            getGameweekResult.position }}</span>
                                            </h6>
                                        </span>
                                        <span class="icon-wrap ml-1">
                                            <v-icon :color="movementColour" class="white--text mr-n1 pb-2">{{
                            positionString
                        }}</v-icon>
                                            <h6 :style="`color: ${movementColour}`">{{ getGameweekResult.positionChange
                            == 0
                            ? '&nbsp;(No Change) ' : getGameweekResult.positionChange }}</h6>
                                        </span>
                                    </div>
                                    <div class="d-flex">
                                        <h6>Points&nbsp;{{ getGameweekResult.weekpoints }}</h6>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-else class="pa-0" no-gutters>
                                <v-col cols="7">
                                    <v-autocomplete v-model="selectedUser" :loading="loading" :items="getSearchedUsers"
                                        :search-input.sync="search" item-text="text" return-object cache-items
                                        hide-no-data outlined clearable hide-details dense ref="searchField"
                                        key="searchField" autofocus filled placeholder="Display name" class="mr-1">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="5">
                                    <v-select id="matchdaySelect" :items="previousGameweeks" label="Matchday" outlined
                                        dense filled hide-details @change="updateGameweek"
                                        v-model="selectedPredictions" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-footer>
                    </div>
                </transition>
            </div>
            <v-row>
                <v-col cols="12">
                    <chart-card>
                        <template slot="chart">
                            <v-col cols="12">
                                <v-data-iterator :items="previousPredictions" :items-per-page.sync="itemsPerPage"
                                    :page="page" hide-default-footer>
                                    <template v-slot:default="props">
                                        <v-row justify="center">
                                            <v-col v-for="(item, index) in props.items" :key="index" cols="12" lg="8"
                                                class="pa-0 mb-2">
                                                <list-card-row-result :id="`match-${index}`" :ref="`match-${index}`"
                                                    :fixture="transformPrediction(item.fixtureEnt)" :prediction="item"
                                                    :progressbar-color="item.progressColor"
                                                    :resultLabel="item.winningMargin.label"
                                                    :winnerLabel="item.matchResult" footer-btn-color="default"
                                                    footer-btn-two="PROFILE" footer-btn-color-two="default"
                                                    class="fixture" />
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-col>
                        </template>
                    </chart-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="12" v-if="false">
            <base-card>
                <v-card-title>
                    Previous Predictions
                </v-card-title>
                <v-card-text>
                    <div>
                        <v-select id="matchdaySelect" :items="previousGameweeks" label="Matchday"
                            @change="updateGameweek" v-model="selectedPredictions" />
                    </div>
                    <div v-for="prediction in previousPredictions" :key="prediction.id">
                        <list-card-row-result :id="`prediction-${prediction.id}`" :ref="`prediction-${prediction.id}`"
                            :fixture="transformPrediction(prediction.fixtureEnt)" :prediction="prediction"
                            :resultLabel="prediction.winningMargin.label" :winnerLabel="prediction.matchResult"
                            :summary="true" footer-btn-color="default" footer-btn-two="PROFILE"
                            footer-btn-color-two="default" class="fixture mt-4" />
                    </div>
                </v-card-text>
            </base-card>
        </v-col>
    </v-row>
</template>
<script>
import ChartCard from '@/components/card/ChartCard'
import ListCardRow from '@/components/card/listCard/ListCardRowSelection'
import ListCardRowResult from '@/components/card/listCard/ListCardRowSelectionResult'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import { gsap } from 'gsap';
import _ from 'lodash';

export default {
    name: 'playerSearch',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Player Search',
    },
    components: {
        ChartCard,
        ListCardRow,
        ListCardRowResult,
    },
    beforeRouteEnter(to, from, next) {
        // Check if the user is logged in or has a valid authentication token
        const isLoggedIn = store.getters.loggedInUser;

        // If the route requires authentication and the user is not logged in, redirect to the login page
        if (to.meta.requiresAuth && !isLoggedIn) {
            next('/login'); // Redirect to the login page
        } else {
            next(); // Continue to the requested route
        }
    },
    mounted() {
        this.selectedPredictions = this.getCurrentGameweek - 1;
        this.fetchCurrentGameweekForCompetition(3); // TODO Hardcoded Comp
    },
    data() {
        return {
            selections: [],
            tab: null,
            itemsPerPage: 6,
            page: 1,
            selectedPredictions: 'Last Week',
            showTextField: false,
            searchText: '',
            loading: false,
            items: [],
            search: null,
            selectedUser: null
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'getCurrentGameweek', 'getPastSelections', 'getGameweekResult', 'getGameweekSnapshot', 'getSearchedUsers']),
        previousPredictions() {
            if (this.selectedPredictions) {
                return this.$store.getters['getPastSelections'].filter(prediction => prediction.fixtureEnt.matchday == this.selectedPredictions)
            }
            return this.$store.getters['getPastSelections'].filter(prediction => prediction.fixtureEnt.matchday == this.$store.getters['getCurrentGameweek'])
        },
        previousGameweeks() {
            let highestNumber = this.$store.getters.getCurrentGameweek;
            let totalWeeks = [];
            for (let i = 1; i <= highestNumber - 2; i++) {
                totalWeeks.push(i)
            }
            totalWeeks.push({ text: 'Last Week', value: this.$store.getters.getCurrentGameweek - 1 });
            return totalWeeks;
        },
        positionString() {
            return this.arrowDisplay(String(this.$store.getters.getGameweekResult.positionChange));
        },
        movementColour() {
            if (!this.ifValueExists(this.getGameweekResult.positionChange)) { return '' };
            if (this.getGameweekResult.positionChange < 0) {
                return 'red'
            } else if (this.getGameweekResult.positionChange == '0') {
                return 'white'
            } else {
                return 'green'
            }
        },
        textFieldStyle() {
            return {
                width: this.showTextField ? '100%' : '0',
            };
        },
        headers() {
            let headers = [
                {
                    text: 'Player',
                    value: 'playerDisplayName', sortable: false
                },
                { text: 'Position', value: 'position', sortable: false },
                // {text: 'Club', value: 'club'},
                { text: 'Gameweek Points', value: 'weekpoints', align: 'center', sortable: false },
            ]
            return headers;
        }
    },
    watch: {
        getCurrentGameweek(val) {
            if (this.selectedUser && this.selectedUser.value) {
                this.fetchPastSelections({ userId: this.selectedUser.value, competitionId: 3 });
                this.selectedPredictions = val - 1;
            }
        },
        search(val) {
            if (val && val !== this.select) {
                this.querySelections(val);
            }
        },
        selectedUser(val) {
            //let selectedUserId = this.findUser(this.selectedUser);
            if(val){
                this.updateUserSelection(this.selectedUser.value);
            } else {
                this.clearPastSelections();
            }
        }
    },
    methods: {
        ...mapActions(["fetchGameWeekSelection", "fetchCurrentGameweekForCompetition", "fetchPastSelections", "fetchGameweekResultForUser", "fetchGameweekSnapshot", "searchByUsername", "clearPastSelections"]),
        arrowDisplay(change) {
            if (!this.ifValueExists(change)) { return '' };
            if (change.indexOf("-") > -1) {
                return 'mdi-triangle-small-down'
            } else if (change == "0") {
                return ''
            } else {
                return 'mdi-triangle-small-up'
            }
        },
        ifValueExists(value) {
            if (value !== null && value !== undefined) {
                return true;
            } else {
                return false;
            }
        },
        transformPrediction(fixture) {
            return {
                id: fixture.id,
                homeTeam: fixture.homeTeam.name,
                homeLogo: fixture.homeTeam.code,
                homeColour: fixture.homeTeam.colour,
                homeScore: `${fixture.homeGoals} ${fixture.homePoints}`,
                awayTeam: fixture.awayTeam.name,
                awayLogo: fixture.awayTeam.code,
                awayColour: fixture.awayTeam.colour,
                awayScore: `${fixture.awayGoals} ${fixture.awayPoints}`,
                date: new Date(fixture.date).toLocaleDateString(),
                time: new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                venue: fixture.venue,
                stage: fixture.stage,
                competition: fixture.competitionEnt.name,
                matchday: fixture.matchday,
                matchResult: fixture.matchResult
            }
        },
        updateGameweek(val) {
            if(!this.selectedUser) return;
            if (val == 'Last Week') {
                this.selectedPredictions = this.$store.getters['getCurrentGameweek'] - 1;
                this.fetchGameweekResultForUser({ userId: this.selectedUser.value, gameweek: this.$store.getters['getCurrentGameweek'], sport: this.$router.currentRoute.meta.sport.toUpperCase() });
            } else {
                this.fetchGameweekResultForUser({ userId: this.selectedUser.value, gameweek: val, sport: this.$router.currentRoute.meta.sport.toUpperCase() });
            }
        },
        toggleTextField() {
            this.showTextField = !this.showTextField;
            if (!this.showTextField) {
                this.selectedUser = null;
            }
        },
        enterAnimation(el, done) {
            gsap.fromTo(el, { opacity: 0, width: 0 }, { opacity: 1, width: '100%', duration: 1, onComplete: done });
        },
        leaveAnimation(el, done) {
            gsap.to(el, { opacity: 0, width: 0, duration: 1, onComplete: done });
        },
        async querySelections(v) {
            if (v.length > 2) {
                this.loading = true
                let response = await this.searchByUsername(v);
                this.loading = false;
                this.items = response.data;
            }
        },
        updateUserSelection(userId) {
            this.fetchPastSelections({ userId: userId, competitionId: 3 });// TODO Hardcoded Comp
        },
        formatNumber(num) {
            return Number.isInteger(num) ? num : num.toFixed(2);
        }
    }
}
</script>

<style scoped>
.fixture_col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixture {
    scroll-margin-top: 20vh;
}

.icon-wrap {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
}

.search-button {
    z-index: 1;
}

.search-field {
    position: absolute;
    right: 0;
    /* Align to the right edge of the parent div */
    top: -20%;
    /* Align to the top of the parent div */
    width: 0;
    /* Start with a width of 0 */
    transition: width 0.5s ease;
    /* Smooth transition for width */
    overflow: hidden;
    /* Hide the overflow when the field is collapsed */
    background-color: grey;
    z-index: 2;
}

.table-headers-word-wrap th {
    white-space: normal;
}

.img_border {
    border-style: solid;
    border-width: 2px;
}

/* .details_height {
    max-height: 200px;
    overflow: hidden;
} */

/* .details_height_mobile {
    max-height: 240px;
    overflow: hidden;
} */

.grow {
  max-height: 70px;
  transition: max-height 0.5s ease-out;
  overflow: hidden; /* Ensure content doesn't overflow while transitioning */
}

.grow-enter-active,
.grow-leave-active {
  transition: max-height 0.5s ease-out;
}

.grow-enter,
.grow-leave-to /* .grow-leave-active in <2.1.8 */ {
  max-height: 0;
}

.grow-expand {
  transition: max-height 0.5s ease-in;
  max-height: 500px; /* Or any max height you expect the element to grow to */
}
</style>